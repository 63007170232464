<template>
	<div id="box">
		<div class="Design">
			<div class="d-top">
				<h1>机 构 清 单</h1>
			</div>
			<div class="d-box">
				<div class="db-l">
					<div class="biao">
						<img src="../../assets/img/Design/1.png" >
					</div>
					<div class="yuan">
						<b>北京印刷学院</b>
						<p>科研机构：国家工程实验室</p>
						<p>北京市重点实验室</p>
						<p>科研成果：<br>《中国印刷业发展研究报告》</p>
					</div>
				</div>
				<div class="db-l">
					<div class="biao">
						<img src="../../assets/img/Design/2.png" >
					</div>
					<div class="yuan">
						<b>浙江理工大学</b>
						<p>科研机构：浙江省级研究中心</p>
						<p>国家地方联合工程研究中心</p>
						<p>科研成果：《现代纺织技术》</p>
					</div>
				</div>
				<div class="db-l">
					<div class="biao">
						<img src="../../assets/img/Design/3.png" >
					</div>
					<div class="yuan">
						<b>猪八戒印艺科技有限公司</b>
						<p>生产范围：出版物 | 印刷品 </p>
						<p>纸制品 | 塑料制品 | 制版</p>
					</div>
				</div>
			</div>
			<!--  -->
			<div class="d-box2">
				<div class="db-l" v-for="(item,i) of bueArr" :key="i">
					<div class="biao">
						<h1>{{item.name}}</h1>
					</div>
					<div class="yuan">
						<b>{{item.title}}</b>
						<p>生产范围：{{item.fw}}</p>
						<p>{{item.fw2}}</p>
						<p>{{item.fw3}}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				bueArr:[
					{
						name:'中心包装', title:'温州中印包装有限公司',
						fw:'纸制品制造', fw2:'包装装潢印刷品印刷', fw3:'塑料制品制造',
					},
					{
						name:'温州万成', title:'温州万成包装印刷有限公司',
						fw:'工艺礼品生产', fw2:'其他印刷品印刷 |  塑料制品', fw3:'',
					},
					{
						name:'龙鼎印业', title:'浙江龙鼎印业有限公司',
						fw:'塑料制品制造', fw2:'包装装潢印刷品印刷', fw3:'资料等其他印刷品印刷',
					},
					{
						name:'非凡印业', title:'浙江非凡印业有限公司',
						fw:'纸制工艺品', fw2:'塑料工艺标牌加工', fw3:'其他印刷品印刷',
					},
					{
						name:'新雅包装', title:'温州蓝睿包装有限公司',
						fw:'纸制品制造', fw2:'资料等其他印刷品印刷', fw3:'塑料制品制造',
					},
					{
						name:'龙鼎印业', title:'浙江新雅包装有限公司',
						fw:'包装材料', fw2:'其他印刷品印刷 | 纸制品', fw3:'',
					}
				]
			}
		}
	}
</script>

<style>
	.Design .d-top{
		height: 60px;
		background: url(../../assets/img/zhengce.png);
		background-size: 100% 100%;
		line-height: 60px;
		text-align: center;
		margin: 0 20px;
	}
	.d-top>h1{
		font-size: 34px !important;
		color: white;
	}
	.d-box{
		margin-top: 40px;
		display: flex;
		justify-content: space-between;
		/* align-items: center; */
	}
	.db-l{
		width: 370px;
		height: 190px;
		background-color: white;
		display: flex;
		justify-content: space-around;
		align-items: center;
		margin: 0 20px;
	}
	.biao>img{
		width: 94px;
		height: 94px;
		margin:0 14px;
	}
	.yuan{
		height: 100%;
		width: 240px;
	}
	.yuan>b{
		display: block;
		text-align: center;
		font-size: 20px;
		margin: 22px 0;
	}
	.yuan>p{
		line-height: 28px;
		font-weight: 800px;
		padding: 0 8px;
		margin: 5px 0;
	}
	.d-box2{
		display: flex;
		flex-wrap: wrap;
	}
	.d-box2 .db-l{
		margin-top: 30px;
		width: 364px;
	}
	.d-box2 .biao{
		width: 94px;
		height: 94px;
		border-radius: 7px;
		text-align: center;
		background-color: #1492FF;
		display: flex;
		align-items: center;
		margin: 0 14px;
	}
	.d-box2 .biao>h1{
		font-size: 32px !important;
		color: white;
	}
	.d-box2 .yuan>p{
		line-height: 28px;
		font-weight: 800px;
		padding: 0 8px;
		margin: 5px 0;
	}
</style>
